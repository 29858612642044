// Based on https://github.com/zellwk/zl-fetch/
/* eslint-disable */

const setHeaders = ({ headers = {}, body, method, username, password, token } = {}) => {
  const h = new Headers(headers)

  if (token) h.set('Authorization', `Bearer ${token}`)
  if (!h.get('content-type')) h.set('content-type', 'application/json')

  return h
}

const queryStringify = (params) => {
  if (!params) return
  return Object.entries(params).reduce((acc, entry, index) => {
    const [param, value] = entry
    const encoded =
      index === 0
        ? `${param}=${encodeURIComponent(value)}`
        : `&${param}=${encodeURIComponent(value)}`
    return `${acc}${encoded}`
  }, '')
}

const createURL = (opts) => {
  const { url, params } = opts
  if (!params) return url
  return `${url}?${queryStringify(params)}`
}

const formatBody = (opts) => {
  const type = opts.headers.get('content-type')

  if (type.includes('json')) return JSON.stringify(opts.body)
  if (type.includes('x-www-form-urlencoded')) return queryStringify(opts.body)
  return opts.body
}

// Defaults to GET method
// Defaults content type to application/json
// Creates authorization headers automatically
const createRequestOptions = (options = {}) => {
  const opts = Object.assign({}, options)

  opts.url = createURL(opts)
  opts.method = opts.method || 'get'
  opts.headers = setHeaders(opts)
  opts.body = formatBody(opts)

  delete opts.username
  delete opts.password
  delete opts.token

  return opts
}

const parseResponse = (response, type) =>
  response[type]().then((body) => formatOutput(response, body))

const getHeaders = (response) => {
  return response.headers.entries ? getBrowserFetchHeaders(response) : getNodeFetchHeaders(response)
}

// window.fetch response headers contains entries method.
const getBrowserFetchHeaders = (response) => {
  const headers = {}
  for (let [header, value] of response.headers.entries()) {
    headers[header] = value
  }
  return headers
}

// Node fetch response headers does not contain entries method.
const getNodeFetchHeaders = (response) => {
  const headers = {}
  const h = response.headers._headers
  for (const header in h) {
    headers[header] = h[header].join('')
  }
  return headers
}

const formatOutput = (response, body) => {
  const headers = getHeaders(response)
  const returnValue = {
    body,
    headers,
    response,
    status: response.status,
    statusText: response.statusText,
  }

  return response.ok ? Promise.resolve(returnValue) : Promise.reject(returnValue)
}

const handleResponse = (response) => {
  const type = response.headers.get('content-type') || response.type
  if (type === null || !type) {
    console.log('handleResponse with null type')
    return {}
  }
  if (response.status === 401) {
    return response.json().then((data) => {
      if (data.message === 'Session not found, please log in again.') {
        window.location.href = '/logout'
      }
      throw new Error('Unauthorized')
    })
  }
  if (type.includes('json')) return parseResponse(response, 'json')
  if (type.includes('text')) return parseResponse(response, 'text')
  if (type.includes('image')) return parseResponse(response, 'blob')
  if (response.status >= 400) throw new Error(`${response.statusText} (${response.status})`)

  // Need to check for FormData, Blob and ArrayBuffer content types
  throw new Error(`zlFetch does not support content-type ${type} yet`)
}

const fetcher = (url, options) => {
  const opts = createRequestOptions(Object.assign({ url }, options))
  return fetch(opts.url, opts).then(handleResponse)
}

export default fetcher
