import { openDB, deleteDB } from 'idb'

const DB_NAME = 'mypetwalker'
const DB_VERSION = 1

class IDB {
  openDB = () =>
    openDB(DB_NAME, DB_VERSION, {
      upgrade(db) {
        db.createObjectStore('pets', {
          keyPath: '_id',
        })
        db.createObjectStore('walks', {
          keyPath: '_id',
        })
      },
    }).catch(e => {
      console.log('openDB error', e)
    })

  deleteDB = () => deleteDB(DB_NAME)

  savePets = async pets => {
    try {
      const db = await this.openDB()
      const tx = db.transaction('pets', 'readwrite')
      pets.forEach(p => {
        tx.store.put(p)
      })
      await tx.done
    } catch (e) {
      console.log('db.savePets error', e)
    }
  }

  savePet = pet => this.savePets([pet])

  getPets = async () => {
    try {
      const db = await this.openDB()
      return db.getAll('pets')
    } catch (e) {
      console.log('db.getPets error', e)
      return e
    }
  }

  updatePet = async pet => {
    try {
      const db = await this.openDB()
      const tx = db.transaction('pets', 'readwrite')
      tx.store.put(pet)
      await tx.done
    } catch (e) {
      console.log('db.updatePet error', e)
    }
  }

  deletePet = async id => {
    try {
      const db = await this.openDB()
      const tx = db.transaction('pets', 'readwrite')
      tx.store.delete(id)
      await tx.done
    } catch (e) {
      console.log('db.getPets error', e)
    }
  }
}

export default new IDB()
