export function isPhoneValid(phoneNumber) {
  const phone = phoneNumber.replace(/ /g, '')
  const isGBLocalNumber = /^0/.test(phone) && phone.length === 11
  const isInternationalGBNumber = /^(\+)(44)/.test(phone) && phone.length === 13
  const isInternationalPLNumber = /^(\+)(48)/.test(phone) && phone.length === 12
  const isInternationalUSANumber = /^(\+)(1)/.test(phone) && phone.length === 14
  const isSpecialNumber = /^(\+)(44)/.test(phone) && phone.length === 17

  return (
    isGBLocalNumber ||
    isInternationalGBNumber ||
    isInternationalPLNumber ||
    isInternationalUSANumber ||
    isSpecialNumber
  )
}

export function formatPhone(phoneNumber) {
  const phone = phoneNumber.replace(/ /g, '')
  const isGBLocalNumber = /^0/.test(phone) && phone.length === 11
  if (isGBLocalNumber) {
    return `+44${phone.slice(1)}`
  }
  return phone
}
