/* eslint no-underscore-dangle: 0 */
import { action, computed, observable, runInAction, makeObservable } from 'mobx'
import viewStore from 'stores/viewStore'
import * as API from 'stores/api'

class InvoiceStore {
  invoices = []

  invoicesList = {}

  error = false

  loadingInvoices = false

  invoiceId = null

  invoice = {}

  loadingInvoice = false

  updatingInvoice = false

  invoiceItems = []

  clientsToInvoice = []

  loadingClients = false

  constructor() {
    makeObservable(this, {
      invoices: observable,
      invoicesList: observable,
      error: observable,
      loadingInvoices: observable,
      invoiceId: observable,
      invoice: observable,
      loadingInvoice: observable,
      updatingInvoice: observable,
      invoiceItems: observable,
      clientsToInvoice: observable,
      loadingClients: observable,
      hasSelectedInvoice: computed,
      createInvoice: action,
      getClientsToInvoice: action,
      getInvoices: action,
      getClientInvoices: action,
      getMyInvoices: action,
      setCurrentInvoice: action,
      getInvoice: action,
      updateInvoice: action,
      deleteDraftInvoice: action,
      deleteInvoiceItem: action,
    })
  }

  get hasSelectedInvoice() {
    return this.invoiceId
  }

  async createInvoice(data) {
    this.updatingInvoice = true

    try {
      const newInvoice = await API.createInvoice(data)
      runInAction(() => {
        this.updatingInvoice = false
      })
      return newInvoice
    } catch (e) {
      console.log('createInvoice failed', e)
      runInAction(() => {
        this.updatingInvoice = false
      })
      return {
        error: e,
        message: e.message || 'Creating invoice failed',
      }
    }
  }

  getClientsToInvoice = async (config) => {
    this.error = null
    try {
      this.loadingClients = true
      const { clients } = await API.getClientsToInvoice(config)

      runInAction(() => {
        this.clients = clients
        this.loadingClients = false
      })
      return clients
    } catch (e) {
      console.log('getClientsToInvoice error', e)
      runInAction(() => {
        this.error = true
        this.loadingClients = false
      })
      return []
    }
  }

  getInvoices = async (config) => {
    this.error = null
    try {
      this.loadingInvoices = true
      const { invoices } = await API.getInvoices(config)

      runInAction(() => {
        if (invoices) {
          this.invoices = invoices
          invoices.forEach((invoice) => {
            this.invoicesList[invoice._id] = {
              value: invoice._id,
              title: invoice.name,
              selected: false,
            }
          })
        }
        this.loadingInvoices = false
      })
      return invoices
    } catch (e) {
      console.log('getInvoices error', e)
      runInAction(() => {
        this.error = true
        this.loadingInvoices = false
      })
      return []
    }
  }

  getClientInvoices = async (clientId) => {
    this.error = null
    try {
      this.loadingInvoices = true
      const { invoices } = await API.getClientInvoices(clientId)
      console.log('XXX', invoices)
      runInAction(() => {
        if (invoices) {
          this.invoices = invoices
          invoices.forEach((invoice) => {
            this.invoicesList[invoice._id] = {
              value: invoice._id,
              title: invoice.name,
              selected: false,
            }
          })
        }
        this.loadingInvoices = false
      })
      return invoices
    } catch (e) {
      console.log('getClientInvoices error', e)
      runInAction(() => {
        this.error = true
        this.loadingInvoices = false
      })
      return []
    }
  }

  getMyInvoices = async () => {
    this.error = null
    try {
      this.loadingInvoices = true
      const { invoices } = await API.getMyInvoices()
      console.log('XXX', invoices)
      runInAction(() => {
        if (invoices) {
          this.invoices = invoices
          invoices.forEach((invoice) => {
            this.invoicesList[invoice._id] = {
              value: invoice._id,
              title: invoice.name,
              selected: false,
            }
          })
        }
        this.loadingInvoices = false
      })
      return invoices
    } catch (e) {
      console.log('getMyInvoices error', e)
      runInAction(() => {
        this.error = true
        this.loadingInvoices = false
      })
      return []
    }
  }

  setCurrentInvoice(id) {
    this.invoiceId = id
  }

  getInvoice = async () => {
    const { invoiceId } = this
    if (!invoiceId) {
      return {}
    }

    this.error = false
    this.loadingInvoice = true
    try {
      const response = await API.getInvoice(invoiceId)
      if (response.error) {
        runInAction(() => {
          this.loadingInvoice = false
          this.invoice = {}
          this.invoiceItems = []
          this.error = 'INVOICE_NOT_FOUND'
        })
        viewStore.notify('danger', 'Invoice not found')
        return {}
      }
      runInAction(() => {
        this.loadingInvoice = false
        this.invoice = response?.invoice
        this.invoiceItems = response?.invoice?.lines?.data || []
      })
      console.log(response.invoice)
      return response.invoice
    } catch (e) {
      console.log('getInvoice error', e)
      runInAction(() => {
        this.loadingInvoice = false
        this.error = true
        this.invoice = {}
      })
      return {}
    }
  }

  updateInvoice = async (id, data) => {
    this.updatingInvoice = true

    try {
      const response = await API.updateInvoice(id, data)
      runInAction(() => {
        this.updatingInvoice = false
        this.invoice = response.invoice
      })
      return response
    } catch (e) {
      console.log('updateInvoice failed', e)
      runInAction(() => {
        this.updatingInvoice = false
      })
      throw e
    }
  }

  deleteDraftInvoice = async (id) => {
    try {
      const result = await API.deleteInvoice(id)
      console.log('deleteDraftInvoice', result)

      if (result.success) {
        viewStore.notify('success', result.message || 'Invoice removed.')
        runInAction(() => {
          this.invoice = {}
          this.invoiceId = null
          this.invoiceItems = []
        })
      } else {
        viewStore.notify('warning', result.message || 'Invoice not removed.')
      }

      return result
    } catch (e) {
      console.log('deleteDraftInvoice error', e)
      return { success: false }
    }
  }

  deleteInvoiceItem = async (invoiceItemId, includeNextTime) => {
    try {
      const result = await API.deleteInvoiceItem(invoiceItemId, {
        includeNextTime,
      })
      console.log('deleteInvoiceItem ', result)

      if (result.success) {
        viewStore.notify('success', result.message || 'Item removed.')

        runInAction(() => {
          this.invoiceItems = this.invoiceItems.filter(
            (item) => item.invoice_item !== invoiceItemId,
          )
          this.invoice.total = this.invoiceItems.reduce((total, item) => total + item.amount, 0)
        })
      } else {
        viewStore.notify('warning', result.message || 'Item not removed.')
      }

      return result
    } catch (e) {
      console.log('deleteInvoiceItem  error', e)
      return { success: false }
    }
  }
}

export default new InvoiceStore()
