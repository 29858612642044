function getAppVersion() {
  return (
    process.env.GATSBY_VERCEL_GIT_COMMIT_SHA || // Vercel https://vercel.com/docs/concepts/projects/environment-variables#system-environment-variables:~:text=GATSBY_VERCEL_GIT_COMMIT_SHA
    process.env.RENDER_GIT_COMMIT || // Render.com https://render.com/docs/environment-variables
    process.env.COMMIT_REF || // Netlify https://docs.netlify.com/configure-builds/environment-variables/
    'UNKNOWN'
  )
}

module.exports = getAppVersion
