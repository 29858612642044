import * as Sentry from '@sentry/gatsby'

import getAppVersion from './src/helpers/getAppVersion'

Sentry.init({
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,

  dsn:
    !process.env.GATSBY_IS_LOCAL &&
    'https://358dafe9150a46b8ac4750b3663f8ca0@o115166.ingest.sentry.io/5513115',
  environment: process.env.GATSBY_ENV,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.GATSBY_ENV === 'development' ? 1.0 : 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    // https://docs.sentry.io/platforms/javascript/guides/gatsby/session-replay/configuration/
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],
  ignoreErrors: [/Network error/],
  release: getAppVersion(),
})
