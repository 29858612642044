import { action, observable, makeObservable } from 'mobx'

import companyStore from './companyStore'

class TourStore {
  clientId

  serviceId

  petId

  constructor() {
    makeObservable(this, {
      clientId: observable,
      serviceId: observable,
      petId: observable,
      setClientId: action,
      setServiceId: action,
      setPetId: action,
    })
  }

  setClientId(clientId) {
    this.clientId = clientId
  }

  setServiceId(serviceId) {
    this.serviceId = serviceId
  }

  setPetId(petId) {
    this.petId = petId
  }

  resetTour = () =>
    companyStore.updateCompany({
      hadTour: false,
    })

  markTourAsComplete = () =>
    companyStore.updateCompany({
      hadTour: true,
    })
}

export default new TourStore()
