/* eslint no-underscore-dangle: 0 */
import { action, computed, observable, runInAction, makeObservable } from 'mobx'
import viewStore from 'stores/viewStore'
import * as API from 'stores/api'

class ServiceStore {
  services = []

  servicesList = {}

  error = false

  loadingServices = false

  serviceId = null

  service = {}

  loadingService = false

  updatingService = false

  constructor() {
    makeObservable(this, {
      services: observable,
      servicesList: observable,
      error: observable,
      loadingServices: observable,
      serviceId: observable,
      service: observable,
      loadingService: observable,
      updatingService: observable,
      hasSelectedService: computed,
      createService: action,
      getServices: action,
      setCurrentService: action,
      getService: action,
      updateService: action,
      updateServicesOrder: action,
      deleteService: action,
    })
  }

  get hasSelectedService() {
    return this.serviceId
  }

  async createService(data) {
    this.updatingService = true

    try {
      const newService = await API.createService(data)
      runInAction(() => {
        this.updatingService = false
      })
      return newService
    } catch (e) {
      console.log('createService failed', e)
      runInAction(() => {
        this.updatingService = false
      })
      return {
        error: e,
        message: e.message || 'Creating service failed',
      }
    }
  }

  getServices = async () => {
    this.error = null
    try {
      this.loadingServices = true
      const { services } = await API.getServices()
      runInAction(() => {
        if (services) {
          this.services = services
          this.servicesList = {}
          services.forEach((service) => {
            if (service.enabled) {
              this.servicesList[service._id] = {
                value: service._id,
                title: service.name,
                selected: false,
                color: service.color,
                minimumBookingsAtOnce: service.minimumBookingsAtOnce,
              }
            }
          })
        }
        this.loadingServices = false
      })
      return services
    } catch (e) {
      console.log('getServices error', e)
      runInAction(() => {
        this.error = true
        this.loadingServices = false
      })
      return []
    }
  }

  setCurrentService(id) {
    this.serviceId = id
  }

  getService = async () => {
    const { serviceId } = this
    if (!serviceId) {
      return {}
    }

    this.error = false
    this.loadingService = true
    try {
      const response = await API.getService(serviceId)
      if (response.error) {
        runInAction(() => {
          this.loadingService = false
          this.service = {}
          this.error = 'SERVICE_NOT_FOUND'
        })
        viewStore.notify('danger', 'Service not found')
        return {}
      }
      runInAction(() => {
        this.loadingService = false
        this.service = response.service
      })
      console.log(response.service)
      return response.service
    } catch (e) {
      console.log('getService error', e)
      // viewStore.notify('warning', 'Loading service failed')
      runInAction(() => {
        this.loadingService = false
        this.error = true
        this.service = {}
      })
      return {}
    }
  }

  // ENABLE SERVICE
  // DISABLE SERVICE
  // UPDATE PRICING

  updateService = async (data, id) => {
    const serviceId = id || this.serviceId
    const serviceData = data
    this.updatingService = true

    try {
      const response = await API.updateService(serviceId, serviceData)
      runInAction(() => {
        this.updatingService = false
        this.service = response.service
      })
      return response
    } catch (e) {
      console.log('updateService failed', e)
      runInAction(() => {
        this.updatingService = false
      })
      return {
        error: e,
        message: e.message || 'Update failed',
      }
    }
  }

  // @action
  // disableService = async id => {
  //   try {
  //     const result = await API.updateService(id, {
  //       enabled: false,
  //     })
  //     console.log('disableService', result)
  //     if (result.success) {
  //       viewStore.notify('success', result.message || `Service disabled.`)
  //     } else {
  //       viewStore.notify('warning', result.message || `Service not disabled.`)
  //     }
  //     return result
  //   } catch (e) {
  //     console.log('disableService error', e)
  //     viewStore.notify('warning', e.message || `Service not disabled.`)
  //     return { success: false }
  //   }
  // }

  updateServicesOrder = async (services) => {
    const sortedServices = this.services.map((s) => {
      const service = s
      const updatedService = services.find((u) => u._id === s._id)
      if (updatedService) {
        service.order = updatedService.order
      }
      return service
    })

    this.services = sortedServices.sort((a, b) => a.order - b.order)

    try {
      await API.updateServicesOrder({
        services: services.map((w) => ({ _id: w._id, order: w.order })),
      })
    } catch (e) {
      console.log('updateServicesOrder error', e)
      // viewStore.notify('warning', e.message || 'Services order not saved')
    }
  }

  deleteService = async (id) => {
    try {
      const result = await API.deleteService(id)
      console.log('deleteService', result)

      if (result.success) {
        viewStore.notify('success', `Service removed.`)
      } else {
        viewStore.notify('warning', `Service not removed.`)
      }
      return result
    } catch (e) {
      console.log('deleteService error', e)
      // viewStore.notify('warning', `Service not removed.`)
      return { success: false }
    }
  }
}

export default new ServiceStore()
