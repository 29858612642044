/* eslint no-underscore-dangle: 0 */
import { action, computed, observable, runInAction, makeObservable } from 'mobx'
import moment from 'moment-timezone'
import profileStore from 'stores/profileStore'
import viewStore from 'stores/viewStore'
import * as API from 'stores/api'
import IDB from 'helpers/idb'

const startOfDay = moment
  .utc()
  .startOf('day') // Show days from start of week
  // .startOf('isoWeek') // Show days from start of week
  .valueOf()

class BookingStore {
  bookingsList = {}

  dogs = {}

  dogsList = {}

  daysToShow = {}

  startDate = ''

  endDate = ''

  comment = ''

  selectedDay = startOfDay

  selectedService = ''

  serviceDetails = {}

  selectedUser = ''

  bookedDays = []

  totalPrice = 0

  discounts = []

  paymentMethod = 'PAY_UPON_BOOKING'

  walks = []

  walkId = null

  walk = {}

  error = false

  loadingDogs = false

  loadingDays = false

  loadingWalks = false

  loadingWalk = false

  loadingGroup = false

  makingBooking = false

  paymentIntentSecret = null

  bookingGroupsCollapsingState = {}

  constructor() {
    makeObservable(this, {
      bookingsList: observable,
      dogs: observable,
      dogsList: observable,
      daysToShow: observable,
      startDate: observable,
      endDate: observable,
      comment: observable,
      selectedDay: observable,
      selectedService: observable,
      serviceDetails: observable,
      selectedUser: observable,
      bookedDays: observable,
      totalPrice: observable,
      discounts: observable,
      paymentMethod: observable,
      walks: observable,
      walkId: observable,
      walk: observable,
      error: observable,
      loadingDogs: observable,
      loadingDays: observable,
      loadingWalks: observable,
      loadingWalk: observable,
      loadingGroup: observable,
      makingBooking: observable,
      paymentIntentSecret: observable,
      bookingGroupsCollapsingState: observable,
      sortedWalks: computed,
      hasSelectedDogs: computed,
      hasSelectedService: computed,
      selectedDogs: computed,
      numOfSelectedDogs: computed,
      selectedDays: computed,
      selectedDaysTimestamps: computed,
      numOfSelectedDays: computed,
      hasSelectedDays: computed,
      selectedDayWalks: computed,
      selectedDaySortedWalks: computed,
      hasSelectedWalk: computed,
      getBookings: action,
      setBookings: action,
      clearBookings: action,
      getDogs: action,
      getCalendarData: action,
      getPrice: action,
      createPaymentIntent: action,
      createBooking: action,
      getWalks: action,
      getUnpaidBookings: action,
      assignStaffToWalk: action,
      cancelWalk: action,
      updateBooking: action,
      updateBookingReport: action,
      getCompanyWalks: action,
      updateWalksOrder: action,
      markBookingsAsPaid: action,
      setCurrentWalk: action,
      getWalk: action,
      rescheduleWalk: action,
      getUserDogs: action,
      createBookingWithoutPayment: action,
      getBookingsGroup: action,
      createGroup: action,
      updateGroup: action,
      deleteBookingsGroup: action,
      toggleGroupVisibility: action,
    })
  }

  get sortedWalks() {
    const active = []
    const cancelled = []
    const archival = []
    const today = moment().utc().startOf('day')
    for (let i = 0; i < this.walks.length; i += 1) {
      const w = this.walks[i]
      if (moment(w.timestamp).isBefore(today)) {
        archival.push(w)
      } else if (w.status === 'CANCELLED' || w.status === 'CANCELLED_REFUNDED') {
        cancelled.push(w)
      } else {
        active.push(w)
      }
    }
    return { active, cancelled, archival }
  }

  get hasSelectedDogs() {
    return Object.values(this.dogsList).filter((dog) => dog.selected).length
  }

  get hasSelectedService() {
    return this.selectedService && this.selectedService.length > 0
  }

  get selectedDogs() {
    const { dogsList } = this
    const selected = Object.values(dogsList).filter((dog) => dog.selected)
    return selected
  }

  get numOfSelectedDogs() {
    const { dogsList } = this
    return Object.values(dogsList).filter((dog) => dog.selected).length
  }

  get selectedDays() {
    const days = this.daysToShow
    const selected = []
    Object.keys(days).forEach((num) => {
      if (days[num].selected) selected.push(days[num])
    })
    return selected
  }

  get selectedDaysTimestamps() {
    const days = this.selectedDays
    const timestamps = days.map((d) => d.timestamp)
    return timestamps
  }

  get numOfSelectedDays() {
    const days = this.daysToShow
    return Object.keys(days).filter((num) => days[num].selected).length
  }

  get hasSelectedDays() {
    return this.numOfSelectedDays > 0
  }

  get selectedDayWalks() {
    const selected = this.walks.filter((w) => moment(w.timestamp).isSame(this.selectedDay, 'day'))
    return selected
  }

  get selectedDaySortedWalks() {
    const groups = {}
    const active = []
    const cancelled = []
    this.selectedDayWalks
      .filter((b) => b.group?.id)
      .forEach((booking) => {
        groups[booking.group._id] = { ...booking.group, bookings: [] }
      })

    this.selectedDayWalks.forEach((w) => {
      if (w.status === 'CANCELLED' || w.status === 'CANCELLED_REFUNDED') {
        cancelled.push(w)
      } else {
        active.push(w)
      }

      if (w.group?._id) {
        groups[w.group._id].bookings.push(w)
      }
    })

    return { active, cancelled, groups }
  }

  get hasSelectedWalk() {
    return this.walkId
  }

  getBookings(formattedDate) {
    return this.bookingsList[formattedDate]
  }

  setBookings(formattedDate, bookings) {
    this.bookingsList[formattedDate] = bookings
  }

  clearBookings() {
    this.bookingsList = {}
  }

  getDogs = async () => {
    runInAction(() => {
      this.loadingDogs = true
    })
    const storageDogs = await IDB.getPets()
    if (storageDogs.length) {
      runInAction(() => {
        this.dogs = storageDogs
      })
    }
    const { user } = profileStore
    const { dogs } = await API.getUserDogs(user._id)
    const dogs2 = {}
    const dogsList = {}
    dogs.forEach((dog) => {
      dogs2[dog._id] = dog

      dogsList[dog._id] = {
        value: dog._id,
        title: dog.name,
        selected: true,
      }
    })

    runInAction(() => {
      this.dogs = dogs2
      this.dogsList = dogsList
      this.loadingDogs = false
    })

    IDB.savePets(dogs)
  }

  handleDogSelect = action((id, checked) => {
    this.dogsList[id].selected = checked
    if (this.hasSelectedDogs && this.hasSelectedService) {
      this.getCalendarData()
    }
  })

  handleServiceSelect = action((id) => {
    this.selectedService = id
    if (this.hasSelectedDogs && this.hasSelectedService) {
      this.getCalendarData()
    }
  })

  handlePaymentMethodSelect = action((name) => {
    console.log('handlePaymentMethodSelect', name)
    this.paymentMethod = name
  })

  mergeDays(newDays) {
    const days = {}
    Object.keys(newDays).forEach((num) => {
      days[num] = newDays[num]
      if (this.daysToShow[num] && this.daysToShow[num].selected && days[num].available) {
        days[num].selected = true
      } else {
        days[num].selected = false
      }
    })
    return days
  }

  getCalendarData = async ({ dogIds, serviceId, reschedulingFromTs, merge = true } = {}) => {
    this.loadingDays = true
    try {
      const data = await API.getAvailableDays(
        serviceId || this.selectedService,
        dogIds || this.selectedDogs.map((dog) => dog.value),
        reschedulingFromTs,
      )
      const { daysToShow, startDate, endDate } = data

      runInAction(() => {
        this.daysToShow = merge ? this.mergeDays(daysToShow) : daysToShow
        this.startDate = startDate
        this.endDate = endDate
        this.loadingDays = false
      })
    } catch (e) {
      console.log('prepareCalendarData error', e)
      runInAction(() => {
        this.loadingDays = false
      })
    }
  }

  handleDaySelect = action((dayNum) => {
    const day = this.daysToShow[dayNum]
    if (!day.available) {
      viewStore.notify('warning', 'This day is unavailable.')
      return
    }
    day.selected = !day.selected
    this.daysToShow[dayNum] = day
  })

  handleSingleDaySelect = action((dayNum) => {
    const day = this.daysToShow[dayNum]
    if (!day.available) {
      viewStore.notify('warning', 'This day is unavailable.')
      return
    }
    Object.keys(this.daysToShow).forEach((d) => {
      this.daysToShow[d].selected = false
    })
    day.selected = true
  })

  setComment = action((comment) => {
    this.comment = comment
  })

  clearStore = action(() => {
    this.comment = ''
    Object.keys(this.daysToShow).forEach((dayNum) => {
      this.daysToShow[dayNum].selected = false
    })
    Object.keys(this.dogsList).forEach((dog) => {
      this.dogsList[dog].selected = true
    })

    this.bookedDays = []
    this.totalPrice = 0
    this.discounts = []
    this.serviceDetails = {}
    this.paymentMethod = 'PAY_UPON_BOOKING'
    this.paymentIntentSecret = null
  })

  getPrice = async () => {
    const {
      selectedDaysTimestamps,
      numOfSelectedDogs,
      selectedService,
      paymentMethod,
      selectedUser,
    } = this
    try {
      const data = await API.getBookingPrice({
        serviceId: selectedService,
        days: selectedDaysTimestamps,
        numOfDogs: numOfSelectedDogs,
        paymentMethod,
        clientId: selectedUser,
      })

      const { totalPrice, days, discounts, service, paymentMethod: newPaymentMethod } = data

      runInAction(() => {
        this.bookedDays = days || []
        this.totalPrice = totalPrice || 0
        this.discounts = discounts || []
        console.log('service', data)
        this.serviceDetails = service || {}
        this.paymentMethod = newPaymentMethod || 'PAY_UPON_BOOKING'
      })
      return data
    } catch (e) {
      console.log('prepareCalendarData error', e)
      return {}
    }
  }

  createPaymentIntent = async () => {
    const { selectedDaysTimestamps, numOfSelectedDogs, selectedService } = this
    try {
      const { paymentIntentSecret } = await API.createPaymentIntent({
        serviceId: selectedService,
        days: selectedDaysTimestamps,
        numOfDogs: numOfSelectedDogs,
      })

      runInAction(() => {
        this.paymentIntentSecret = paymentIntentSecret
      })
      return paymentIntentSecret
    } catch (e) {
      console.log('createPaymentIntent error', e)
      throw e
    }
  }

  createBooking = async ({ paymentIntent }) => {
    const { selectedDogs, selectedDaysTimestamps, comment, selectedService } = this

    this.makingBooking = true

    const body = {
      serviceId: selectedService,
      dogs: selectedDogs.map((d) => d.value),
      days: selectedDaysTimestamps,
      comment,
      paymentIntent,
    }

    try {
      const response = await API.createBooking(body)
      console.log('createdBooking', response)

      if (!response.success) {
        viewStore.notify('warning', 'Creating booking failed.')
      }
      return response
    } catch (e) {
      console.log('createBooking error', e)
      // viewStore.notify('warning', `Creating booking failed. Try again.`)
      return {}
    } finally {
      runInAction(() => {
        this.makingBooking = false
      })
    }
  }

  getWalks = async () => {
    const { user } = profileStore
    // const storageWalks = JSON.parse(localStorage.getItem('walks'))
    // if (storageWalks) {
    //   this.walks = storageWalks
    // }
    this.error = null
    try {
      this.loadingWalks = true
      const params = {
        fromTime: moment().subtract(14, 'days').startOf('day').valueOf(),
      }
      const { walks } = await API.getUserWalks(user._id, params)
      runInAction(() => {
        if (walks) {
          this.walks = walks
          // localStorage.setItem('walks', JSON.stringify(walks))
        }
        this.loadingWalks = false
      })
    } catch (e) {
      console.log('getWalks error', e)
      runInAction(() => {
        this.error = true
        this.loadingWalks = false
      })
    }
  }

  getUnpaidBookings = async (userId) => {
    this.error = null
    try {
      this.loadingWalks = true

      const params = {
        unpaidCash: true,
        all: true,
      }

      const { walks } = await API.getUserWalks(userId, params)
      runInAction(() => {
        if (walks) {
          this.walks = walks
        }
        this.loadingWalks = false
      })
      return walks
    } catch (e) {
      console.log('getWalks error', e)
      runInAction(() => {
        this.error = true
        this.loadingWalks = false
      })
      return []
    }
  }

  assignStaffToWalk = async (id, staffId) => {
    try {
      const result = await API.assignStaffToWalk(id, {
        assignedStaff: staffId,
      })
      // console.log('assignStaffToWalk', result, result.success)
      if (result.success) {
        viewStore.notify('success', `Team member ${staffId ? '' : 'un'}assigned.`)
        runInAction(() => {
          this.walk.assignedStaff = result.walk.assignedStaff || null
          const walk = this.walks[this.walks.findIndex((el) => el._id === id)]
          if (walk) walk.assignedStaff = result.walk.assignedStaff || null
        })
      } else {
        throw Error(result?.message || result)
      }
      return result
    } catch (e) {
      console.log('assignStaffToWalk error', e)
      throw Error(e)
      // viewStore.notify('warning', `Team member not assigned.`)
    }
  }

  cancelWalk = async (id, forceRefund) => {
    const body = {
      forceRefund,
    }

    try {
      const result = await API.cancelWalk(id, body)
      if (result.success) {
        viewStore.notify('success', result.message || 'Booking cancelled successfuly.')
        runInAction(() => {
          if (this.hasSelectedWalk) {
            this.walk.status = result.walk.status
            this.walk.canBeRescheduled = result.walk.canBeRescheduled
          }
        })
      } else {
        viewStore.notify('warning', result.message || 'Booking not cancelled. Contact us.')
      }
      this.getWalks()
    } catch (e) {
      console.log('cancelWalk error', e)
      // viewStore.notify('warning', `Booking not cancelled. Contact us.`)
    }
  }

  updateBooking = async (id, data) => {
    try {
      const result = await API.updateBooking(id, data)
      console.log('updateBooking', result, result.success)
      if (result.success) {
        viewStore.notify('success', result.message || 'Booking updated')
        runInAction(() => {
          this.walk = { ...this.walk, ...result.walk }
        })
      } else {
        throw Error(result?.message || result)
      }
    } catch (e) {
      console.log('updateBooking error', e)
      throw e
      // viewStore.notify('warning', `Team member not assigned.`)
    }
  }

  updateBookingReport = async (id, data) => {
    try {
      const result = await API.updateBookingReport(id, data)
      console.log('updateBookingReport', result)
      if (result.success) {
        runInAction(() => {
          this.walk.report = result.walk.report || {}
          const walk = this.walks[this.walks.findIndex((el) => el._id === id)]
          if (walk) walk.report = result.walk.report || {}
        })
      } else {
        throw Error(result?.message || result)
      }
    } catch (e) {
      console.log('updateBookingReport error', e)
      throw e
      // viewStore.notify('warning', `Team member not assigned.`)
    }
  }

  /**
   * ADMIN
   */

  getCompanyWalks = async (config = {}) => {
    this.error = null

    const params = { ...config }
    if (!params.fromTime && Object.keys(this.daysToShow).length) {
      const lastDay = Object.keys(this.daysToShow)[Object.keys(this.daysToShow).length - 1] || '90'
      params.fromTime = moment(this.daysToShow['1'].timestamp).valueOf()
      params.toTime = moment(this.daysToShow[lastDay].timestamp).valueOf()
    }

    try {
      this.loadingWalks = true

      const { walks, daysToShow, startDate, endDate } = await API.getCompanyWalks(params)
      runInAction(() => {
        if (walks) {
          this.walks = walks
          this.daysToShow = { ...daysToShow }
          this.startDate = startDate
          this.endDate = endDate
        }
        this.loadingWalks = false
      })
    } catch (e) {
      console.log('getCompanyWalks error', e)
      runInAction(() => {
        this.error = true
        this.loadingWalks = false
      })
    }
  }

  handleAdminDaySelect = action((timestamp) => {
    this.selectedDay = timestamp
  })

  updateWalksOrder = async (walks) => {
    const sortedWalks = this.walks.map((w) => {
      const walk = w
      const updatedWalk = walks.find((u) => u._id === w._id)
      if (updatedWalk) {
        walk.order = updatedWalk.order
      }
      return walk
    })

    this.walks = sortedWalks.sort((a, b) => a.order - b.order)

    try {
      await API.updateWalksOrder({
        walks: walks.map((w) => ({ _id: w._id, order: w.order })),
      })
    } catch (e) {
      console.log('updateWalksOrder error', e)
      // viewStore.notify('warning', e.message || 'Bookings order not saved')
    }
  }

  markBookingsAsPaid = async (bookings, paymentMethod) => {
    try {
      const result = await API.markBookingsAsPaid({
        bookings,
        paymentMethod,
      })

      if (result.bookings) {
        // Update bookings locally
        const newBookings = [...this.walks]
        result.bookings.forEach((newBooking) => {
          console.log(newBooking)
          const index = newBookings.findIndex((b) => b._id === newBooking._id)
          if (index >= 0) {
            newBookings[index] = { ...newBookings[index], status: newBooking.status }
          }
        })
        runInAction(() => {
          this.walks = newBookings
        })
      }

      return result
    } catch (e) {
      console.log('markBookingsAsPaid error', e)
      // viewStore.notify('warning', e.message || 'Bookings order not saved')
      return e
    }
  }

  setCurrentWalk(id) {
    this.walkId = id
  }

  getWalk = async () => {
    const { walkId } = this
    if (!walkId) {
      return {}
    }

    this.error = false
    this.loadingWalk = true

    try {
      const data = await API.getWalk(walkId)
      if (data.error) {
        runInAction(() => {
          this.walk = {}
          this.error = 'WALK_NOT_FOUND'
          this.loadingWalk = false
        })
        viewStore.notify('danger', 'Booking not found')
        return {}
      }
      runInAction(() => {
        this.walk = data.walk
        this.loadingWalk = false
      })
      return data.walk
    } catch (e) {
      console.log('getWalk error', e)
      runInAction(() => {
        this.error = true
        this.walk = {}
        this.loadingWalk = false
      })
      return {}
    }
  }

  rescheduleWalk = async (walkId, newTimestamp) => {
    try {
      const result = await API.rescheduleWalk(walkId, {
        newTimestamp,
      })

      if (result.success) {
        runInAction(() => {
          this.walk = { ...result.walk, ...this.walk }
        })
        viewStore.notify('success', result.message)
      }
      return result
    } catch (e) {
      console.log('rescheduleWalk error', e)
      // viewStore.notify('warning', e.message || 'Booking day not changed (#2)')
      return {}
    }
  }

  /**
   * ADMIN
   */

  selectUser = action((userId) => {
    this.selectedUser = userId
  })

  getUserDogs = async (userId) => {
    const { dogs } = await API.getUserDogs(userId)
    const dogs2 = {}
    const dogsList = {}
    dogs.forEach((dog) => {
      dogs2[dog._id] = dog

      dogsList[dog._id] = {
        value: dog._id,
        title: dog.name,
        selected: true,
      }
    })

    runInAction(() => {
      this.dogs = dogs2
      this.dogsList = dogsList
    })
  }

  createBookingWithoutPayment = async ({ clientId } = {}) => {
    const {
      selectedDogs,
      selectedDaysTimestamps,
      comment,
      selectedService,
      selectedUser,
      paymentMethod,
    } = this

    this.makingBooking = true

    const body = {
      serviceId: selectedService,
      clientId: clientId || selectedUser,
      dogs: selectedDogs.map((d) => d.value),
      days: selectedDaysTimestamps,
      comment,
      paymentMethod,
    }

    try {
      const response = await API.createBookingWithoutPayment(body)
      console.log('createdAdminBooking', response)
      if (!response.success) {
        viewStore.notify('warning', response.message || 'Creating booking failed.')
      }
      return response
    } catch (e) {
      console.log('createBooking error', e)
      // viewStore.notify('warning', `Creating booking failed. Try again.`)
      return {}
    } finally {
      runInAction(() => {
        this.makingBooking = false
      })
    }
  }

  // Booking groups
  getBookingsGroup = async (id) => {
    this.loadingGroup = true
    try {
      const response = await API.getBookingsGroup(id)
      if (!response.success) {
        viewStore.notify('warning', response.message || 'Loading group failed.')
      }
      return response.group
    } catch (e) {
      console.log('getBookingsGroup error', e)
      return {}
    } finally {
      runInAction(() => {
        this.loadingGroup = false
      })
    }
  }

  createGroup = async ({ bookings, name, assignedStaff, notes }) => {
    this.loadingGroup = true

    const body = {
      bookings,
      name,
      assignedStaff,
      notes,
    }

    try {
      const response = await API.createBookingsGroup(body)
      console.log('createGroup', response)
      if (!response.success) {
        viewStore.notify('warning', response.message || 'Creating group failed.')
      }
      return response
    } catch (e) {
      console.log('createGroup error', e)
      return {}
    } finally {
      runInAction(() => {
        this.loadingGroup = false
      })
    }
  }

  updateGroup = async (id, { bookings, name, assignedStaff, notes }) => {
    this.loadingGroup = true

    const body = {
      bookings,
      name,
      assignedStaff,
      notes,
    }

    try {
      const response = await API.updateBookingsGroup(id, body)
      if (!response.success) {
        viewStore.notify('warning', response.message || 'Updating group failed.')
      }
      return response
    } catch (e) {
      console.log('updateGroup error', e)
      return {}
    } finally {
      runInAction(() => {
        this.loadingGroup = false
      })
    }
  }

  deleteBookingsGroup = async (id) => {
    this.loadingGroup = true

    try {
      const response = await API.deleteBookingsGroup(id)
      console.log('deleteGroup', response)
      if (!response.success) {
        viewStore.notify('warning', response.message || 'Deleting group failed.')
      }
      return response
    } catch (e) {
      console.log('deleteGroup error', e)
      return {}
    } finally {
      runInAction(() => {
        this.loadingGroup = false
      })
    }
  }

  toggleGroupVisibility = (id) => {
    this.bookingGroupsCollapsingState[id] = !this.bookingGroupsCollapsingState[id]
  }
}

export default new BookingStore()
