/* eslint no-underscore-dangle: 0 */
import { action, computed, observable, runInAction, makeObservable } from 'mobx'
import viewStore from 'stores/viewStore'
import * as API from 'stores/api'

class DogStore {
  error = false

  loadingDogs = false

  dogId = null

  dog = {}

  loadingDog = false

  updatingDog = false

  uploadingPhoto = false

  constructor() {
    makeObservable(this, {
      error: observable,
      loadingDogs: observable,
      dogId: observable,
      dog: observable,
      loadingDog: observable,
      updatingDog: observable,
      uploadingPhoto: observable,
      hasSelectedDog: computed,
      dogPhoto: computed,
      setCurrentDog: action,
      getDog: action,
      createDog: action,
      updateDog: action,
      deleteDog: action,
      uploadDogPhoto: action,
      deleteDogPhoto: action,
    })
  }

  get hasSelectedDog() {
    return this.dogId
  }

  get dogPhoto() {
    return this.dog.photo
  }

  setCurrentDog(id) {
    this.dogId = id
  }

  getDog = async () => {
    const { dogId } = this
    if (!dogId) {
      return {}
    }

    this.error = false
    this.loadingDog = true
    try {
      const data = await API.getDog(dogId)

      if (data.error) {
        runInAction(() => {
          this.loadingDog = false
          this.error = 'USER_NOT_FOUND'
          this.dog = {}
        })
        viewStore.notify('danger', 'Dog not found')
        return {}
      }
      runInAction(() => {
        this.loadingDog = false
        this.dog = data.dog
      })

      return data.dog
    } catch (e) {
      console.log('getDog error', e)
      // viewStore.notify('warning', 'Loading dog failed')
      runInAction(() => {
        this.loadingDog = false
        this.error = true
        this.dog = {}
      })
      return {}
    }
  }

  async createDog(data) {
    this.updatingDog = true

    try {
      const newDog = await API.createDog(data)
      runInAction(() => {
        this.updatingDog = false
      })
      return newDog
    } catch (e) {
      console.log('createDog failed', e)
      runInAction(() => {
        this.updatingDog = false
      })
      return {
        error: e,
        message: e.message || 'Creating dog failed',
      }
    }
  }

  async updateDog(data) {
    const { dogId } = this
    this.updatingDog = true

    try {
      const response = await API.updateDog(dogId, data)
      runInAction(() => {
        this.updatingDog = false
        this.dog = response.dog
      })

      return response
    } catch (e) {
      console.log('updateDog failed', e)
      runInAction(() => {
        this.updatingDog = false
      })
      throw e
    }
  }

  deleteDog = async (id) => {
    try {
      const result = await API.deleteDog(id)
      console.log('deletePet', result)
      if (result.success) {
        viewStore.notify('success', `Pet removed.`)
      } else {
        viewStore.notify('warning', `Pet not removed.`)
      }
      return result
    } catch (e) {
      console.log('deletePet error', e)
      // viewStore.notify('warning', `Pet not removed.`)
      return { success: false }
    }
  }

  uploadDogPhoto = async (photo) => {
    const { dogId } = this
    this.uploadingPhoto = true

    try {
      const data = await API.uploadDogPhoto(dogId, photo)
      runInAction(() => {
        this.uploadingPhoto = false
        console.log('UPLOADED PHOTO', data)
        if (data.dog) {
          this.dog = data.dog
        }
      })

      viewStore.notify('success', `Pet photo uploaded.`)

      return data
    } catch (e) {
      console.log('uploadDogPhoto failed', e)
      runInAction(() => {
        this.uploadingPhoto = false
      })
      viewStore.notify('warning', e.message || 'Update failed')

      return {
        error: e,
        message: e.message || 'Update failed',
      }
    }
  }

  deleteDogPhoto = async () => {
    const { dogId } = this
    try {
      const result = await API.deleteDogPhoto(dogId)
      console.log('deletePetPhoto', result)
      if (!result.dog.photo) {
        viewStore.notify('success', `Pet photo removed.`)
        runInAction(() => {
          this.dog.photo = null
        })
      } else {
        viewStore.notify('warning', `Pet photo not removed.`)
      }
      return result
    } catch (e) {
      console.log('deletePetPhoto error', e)
      // viewStore.notify('warning', `Pet photo not removed.`)
      return { success: false }
    }
  }
}

export default new DogStore()
